@import "~@/assets/scss/global.scss";













































































































































































































































































































































































































































































































































































































































































#order-content {
  #trade {
    ::v-deep {
      .el-input {
        width: 150px;
      }

      .el-date-editor.el-input {
        width: 130px;
      }

      .el-date-editor--timerange.el-input__inner {
        width: 120px;
      }

      .el-icon-time,
      .el-range__close-icon {
        display: none;
      }

      .el-switch {
        &:last-child {
          margin-right: 0;
        }
      }

      .el-footer {
        font-size: 15px;
        font-weight: bold;
        line-height: 40px;

        span {
          display: inline-block;
          width: 25%;

          &:last-child {
            width: calc(25% - 10px);
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }
  }

  #detail {
    .el-textarea {
      height: 100%;
      width: calc(100%);
    }

    ::v-deep {
      .el-textarea__inner {
        height: 100%;
      }

      .el-footer {
        font-size: 15px;
        font-weight: bold;
        line-height: 40px;

        div {
          display: inline-block;
          width: 50%;

          &>span {
            display: inline-block;
            width: 50%;
          }

          .el-switch__label {
            width: auto;
            margin-right: 10px;

            .el-switch__label {
              width: auto;
            }
          }

          &:last-child {
            text-align: right;
          }

          &>span.btn_popover {
            display: inline !important;
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}

::v-deep {
  .report-dialog {

    .el-icon-time,
    .el-range__close-icon {
      display: none;
    }

    .el-date-editor--timerange.el-input__inner {
      padding-left: 10px;
      width: 180px;
    }

    .el-input__inner {
      padding-left: 15px;
      width: 100px;
    }
  }

  .el-main,
  .el-footer {
    border-top: 0px !important;
  }

  #detail {
    .el-radio-button {
      border-bottom: 2px solid #e4e7ed;
      height: 40px;
      line-height: 40px;

      .el-radio-button__inner {
        border: 0px;
        font-size: 14px;
        font-weight: 500;
        background-color: #ffffff;
        color: #303133;
      }
    }

    .el-radio-button.is-active {
      border-bottom: 2px solid #409eff;

      .el-radio-button__inner {
        border: 0px;
        background-color: #ffffff;
        color: #409EFF;
        font-size: 14px;
        font-weight: 500;
        ;
        -webkit-box-shadow: 0px 0 0 0 #409EFF;
      }
    }
  }


}
